













































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { UserNoteUpdateModel } from "@/api/generated"; // Assuming you have UserNoteUpdateModel defined in the generated API

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UserNoteUpdateDialog extends Validation {
  @Prop(String) private userNoteId!: string; // Assuming you pass the userNoteId as a prop from the parent component
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: UserNoteUpdateModel = {
    title: "",
    note: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.UserNoteService.apiUsernoteUserNoteIdGet(
        this.userNoteId
      );
      this.model = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch user note");
      this.syncedDialog = false;
    }
  }

  private async onNoteUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.UserNoteService.apiUsernoteUserNoteIdPut(
        this.userNoteId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Note updated");
      this.$emit("note-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update note");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
