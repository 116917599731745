




















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserNoteCreateDialog from "@/components/dialogs/UserNoteCreateDialog.vue";
import UserNoteUpdateDialog from "@/components/dialogs/UserNoteUpdateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { UserNoteEvidenceModel, UserNoteModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { UserNoteCreateDialog, UserNoteUpdateDialog } })
export default class CrewMemberNotes extends Vue {
  @Prop(String) private userId!: string;
  private userNoteId = "";
  private updateDialog = false;
  private createDialog = false;
  private loading = false;
  private items: Array<UserNoteModel> = [];
  private noteEvidence: Array<UserNoteEvidenceModel> = [];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getUserNotes();
  }

  private async getUserNotes() {
    try {
      this.loading = true;
      const response = await Api.UserNoteService.apiUsernoteUserUserIdGet(
        this.userId
      );
      this.items = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew member notes");
    } finally {
      this.loading = false;
    }
  }

  private onCreateNote() {
    this.createDialog = true;
  }

  private async onDeleteNote(item: UserNoteModel) {
    if (!item.userNoteId) {
      return;
    }

    try {
      this.loading = true;
      await Api.UserNoteService.apiUsernoteUserNoteIdDelete(item.userNoteId);
      snackbarModule.setSnackbarMessage("Note deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete note");
    } finally {
      this.loading = false;
    }

    await this.getUserNotes();
  }

  private onUpdateNote(item: UserNoteModel) {
    if (!item.userNoteId) {
      return;
    }

    this.userNoteId = item.userNoteId;
    this.updateDialog = true;
  }

  private async onDownloadEvidence(evidence: UserNoteEvidenceModel) {
    if (!evidence.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      evidence.documentId
    );
    if (evidence.fileName != null) {
      FileDownloadUtility.DownloadFile(fileResponse.data, evidence.fileName);
    }
  }
}
